<div class="dialog-container">
    <h1 class="header">ChatLoopa</h1>

    <div class="initial-dialog-content">
        {{ 'mainDialogText' | translate }}
    </div>

    <div class="checkbox-container">
        <mat-checkbox (change)="disableContinueButton($event)">
            {{ 'agreementText' | translate }}
        </mat-checkbox>
    </div>

    <div class="dialog-action-button-container">
        <button mat-raised-button class="text-chat-action-button" id="continue-dialog-button" color="success" mat-dialog-close [disabled]="disableObservable|async" (click)="continueToTextChat()">Text Chat</button>

        <button mat-raised-button class="continue-dialog-button" id="continue-dialog-button" color="success" mat-dialog-close [disabled]="disableObservable|async" (click)="continueToVideoChat()">Video Chat</button>
    </div>
</div>