import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TextChatComponent } from './components/text-chat/text-chat.component';
import { WebConferenceComponent } from './components/web-conference/web-conference.component';
import { HomeComponent } from './components/home/home.component';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' }, // Default route
  { path: '', component: HomeComponent },
  { path: 'text', component: TextChatComponent },
  { path: 'video', component: WebConferenceComponent },
  //Wild Card Route for 404 request
  { path: '**', pathMatch: 'full', 
      component: HomeComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
