import { Component, } from '@angular/core';

@Component({
  selector: 'app-local-video',
  templateUrl: './local-video.component.html',
  styleUrls: ['./local-video.component.scss']
})
export class LocalVideoComponent {
  sourceObject: any;
  
  constructor() { }

}
