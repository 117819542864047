<mat-toolbar>
    <span class="chatloopa-name">ChatLoopa</span>
    <span class="toolbar-spacer"></span>
    <button mat-raised-button class="next-peer" id="next-peer" (click)="startService()" color="primary" matTooltip="{{ 'nextPeerTooltip' | translate }}">{{ 'nextPeer' | translate }} <mat-icon class="purple-icon">skip_next</mat-icon></button>
</mat-toolbar>

<div class="main-container">
    <div #chatContainer class="chat-container">
        <strong *ngIf="!isConnected">Looking for someone you can chat with...</strong><br />
        <strong *ngIf="isConnected">You are connected with a random person on ChatLoopa!</strong><br />
        <div class="message-content" *ngFor="let message of messages">
            <strong><span class="user" *ngIf="userId === message.senderId">You:</span><span class="stranger" *ngIf="userId !== message.senderId">Stranger:</span></strong> <span class="message">{{ message.message }}</span><br />
        </div>
    </div>
    
    <div class="messaging-container">
        <input #messageBox mat-input class="message-box" (keydown.enter)="sendMessage(messageBox.value)" />
        <button mat-raised-button class="send-button" color="primary" (click)="sendMessage(messageBox.value)">Send</button>
    </div>
</div>
